<template>
  <div class="bg">
    <div class="wrapper">
      <div>
    <div _ngcontent-c9="" class="title">
      <h6 _ngcontent-c9="">实训基地</h6>
    </div>
    <div _ngcontent-c9="" class="content">

      <p _ngcontent-c9="" class="text-title">基地简介</p>
      <p _ngcontent-c9="" class="intro">
        为提升交通运输行业网络安全从业人员专业技术水平，增强安全管理和网络攻防对抗能力，建立健全实战导向的行业网络安全教育训练体系，在部科技司指导下，部管理干部学院联合国内多家网络安全知名单位，建设了交通运输行业网络安全攻防训练基地（以下简称“训练基地”）。
      </p>
      <p _ngcontent-c9="" class="intro">
        训练基地充分利用部管理干部学院现有培训资源优势，以行业网络安全教育训练为重点，突出实战化特色，培训坚持“三结合”，即理论与实践相结合、“线上学”与“线下练”相结合、行业信息化发展与网络安全保障相结合。通过体系化课程、实战化训练、专业级考核，切实提高教育培训的专业性、针对性、有效性，着力培养知大势、懂技术、会管理、能实操的安全管理和技术人员，不断满足交通运输行业网络安全人才培养需求，解决目前行业网络安全人才供应不足的基本问题，为加快建设交通强国提供高质量的行业网络安全人才。
      </p>
      <img _ngcontent-c9="" alt="" src="../../assets/images/djt/trainbase1.png" style="display: block;margin: 10px auto;">
      
      
      <div _ngcontent-c9="" class="intro" style="text-indent: 0em;">
        <p _ngcontent-c9="" style="text-align: center;margin: 20px;font-size: 24px;"><strong _ngcontent-c9="">学习环境</strong></p>
        <p _ngcontent-c9="" class="intro">训练基地配置先进的软硬件设施，建立便捷、舒适、安全的学习环境。</p>
        <img _ngcontent-c9="" alt="" src="../../assets/images/djt/trainbase2.png" style="display: block;margin: 10px auto;width:1000px;">
        <p _ngcontent-c9="" style="text-align: center;margin: 20px;font-size: 24px;"><strong _ngcontent-c9="">场地设施</strong></p>
        <p _ngcontent-c9="" class="intro">• 授课教室3个，约280平米；</p>
        <p _ngcontent-c9="" class="intro">• 培训实操教室2个，约270平米；</p>
        <p _ngcontent-c9="" class="intro">• 比赛大厅和产品展示厅，约350平米；</p>
        <p _ngcontent-c9="" class="intro">• 研讨、备课、会议室，约100平米；</p>
        <p _ngcontent-c9="" class="intro">• 休息区，约100平米；</p>
        <p _ngcontent-c9="" class="intro">• 可供400多人学习培训。</p>
        <img _ngcontent-c9="" alt="" src="../../assets/images/djt/trainbase3.png" style="display: block;margin: 10px auto;width:400px">
        <p _ngcontent-c9="" style="text-align: center;margin: 20px;font-size: 24px;"><strong _ngcontent-c9="">学习条件</strong></p>
        <p _ngcontent-c9="" class="intro">• 硬件：实训计算机130台；</p>
        <p _ngcontent-c9="" class="intro">• 软件：部署培训实操平台和攻防演练平台各一套；</p>
        <p _ngcontent-c9="" class="intro">• 设置18类，共计157门课程。</p>
      </div>
    </div>
  </div>
    </div>
  </div>
  
</template>

<style lang="less" scoped>
  .bg {
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #4F17A8;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
